
    .login-container {
        /*width: 1200px;*/
        /*min-width: 1200px;*/
        margin: 0 auto;
        display: flex;
        min-height: calc(100% - 84px);
        justify-content: center;
        background: #fff;
        .login-photo {
            width: 612px;
            height: 422px;
            margin-top: 100px;
            background: url("../../assets/images/login_img.png") no-repeat 0 0;
            background-size:  80% 80%;
            margin-left: 94px;
        }
        .login-border {
            width: 52px;
            margin-left: -50px;
            margin-top: -30px;
            background: url("../../assets/images/border.png") no-repeat 0 0;
        }
        .login-form {
            margin-bottom: 60px;
            width: 340px;
            .login-title {
                margin-top: 120px;
                .greetings {
                    font-size: 28px;
                    margin: 0 0 1px;
                }
                .welcome {
                    color: #888;
                    font-size: 18px;
                    margin: 0 0 15px;
                    .jd-course {
                        color: #ff9204;
                    }
                }
            }
            .login-content {
                .el-form-item {
                    margin-bottom: 32px;
                }
                ::v-deep .el-input {
                    .el-input__inner {
                        height: 45px;
                        line-height: 43px;
                        padding: 0 40px;
                        border-radius: 3px;
                        background: #f4f4f4;
                        border: 1px solid #c1c1c1;
                        &:focus {
                            color: #495057;
                            background-color: #fff;
                            outline: none;
                            border-color: #4A90E2;
                            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
                        }
                    }
                    .el-input__prefix {
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        transform: translateY(-50%);
                        display: flex;
                        align-items: center;
                        .iconfont {
                            font-size: 20px;
                        }
                    }
                }
                .submit-btn {
                    width: 100%;
                    font-size: 16px;
                    height: 45px;
                    border-radius: 0;
                    border: none;
                    background: linear-gradient(to bottom right, #F35552 , #E31D1A);
                }
            }
        }
    }
